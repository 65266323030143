
import { PropType } from 'vue'

export default {
  name: 'Avatar',
  props: {
    size: {
      type: Number as PropType<number>,
      default: 32
    }
  },
  setup() {
    const avatar = require('@/assets/img/account/avatar.png')
    return { avatar }
  }
}
