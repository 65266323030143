
import { defineComponent } from 'vue'
import Avatar from './avatar.vue'
import { useStore } from '@/store'

export default defineComponent({
  name: 'PersonalInfo',
  components: { Avatar },
  setup() {
    const {
      state: { login }
    } = useStore()
    const user = login.user

    return {
      user
    }
  }
})
